import { useMemo } from 'react'
import styled from 'styled-components'
import { Text, Flex, CloseIcon, IconButton, useMatchBreakpointsContext } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { usePhishingBannerManager } from 'state/user/hooks'

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  padding: 8px 10px;
  align-items: center;
  background: ${({ theme }) =>
    theme.isDark ? theme.colors.backgroundAlt2 : 'linear-gradient(100deg,rgb(253 245 255) 0%,rgb(229 253 255) 100%)'};
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 8px 20px;
  }
`

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const WarningText = styled.div`
  padding: 0 0 0 10px;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 0 0 10px;
  }

  & ${Text} {
    color: ${({ theme }) => theme.colors.textSubtle};
    flex-shrink: 0;
    margin-right: 4px;
  }
`

const PhishingWarningBanner: React.FC = () => {
  const { t } = useTranslation()
  const [, hideBanner] = usePhishingBannerManager()
  const { isMobile, isMd } = useMatchBreakpointsContext()
  const warningTextAsParts = useMemo(() => {
    const warningText = t('Please make sure you are on zswap.plus ')
    return warningText.split(/(zswap.plus)/g)
  }, [t])
  const warningTextComponent = (
    <>
      {/* <Text as="span" color="#D04DF5" small bold textTransform="uppercase">
        {t('Phishing warning: ')}
      </Text> */}
      {warningTextAsParts.map((text, i) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          small
          as="span"
          bold={text === 'zswap.plus'}
          color={text === 'zswap.plus' ? '#D04DF5 !important' : 'initial'}
        >
          {text}
        </Text>
      ))}
    </>
  )
  return (
    <Container className="warning-banner">
      {isMobile || isMd ? (
        <>
          <WarningText>{warningTextComponent}</WarningText>
          <IconButton onClick={hideBanner} variant="text" scale="sm">
            <CloseIcon  /> {/* removed: color="#FFFFFF" */}
          </IconButton>
        </>
      ) : (
        <>
          <InnerContainer>
            {/* 
            <picture>
              <source type="image/webp" srcSet="/images/decorations/phishing-warning-bunny.webp" />
              <source type="image/png" srcSet="/images/decorations/phishing-warning-bunny.png" />
              <img src="/images/decorations/phishing-warning-bunny.png" alt="phishing-warning" width="92px" />
            </picture>
            */}
            <WarningText>{warningTextComponent}</WarningText>
          </InnerContainer>
          <IconButton onClick={hideBanner} variant="text" scale="sm">
            <CloseIcon /> {/* removed: color="#FFFFFF" */}
          </IconButton>
        </>
      )}
    </Container>
  )
}

export default PhishingWarningBanner
