import { useMemo } from 'react'
import { menuStatus } from '@pancakeswap/uikit'
import { useTranslation } from '../../../contexts/Localization'
import { useMenuItemsStatus } from './useMenuItemsStatus'
import config, { ConfigMenuItemsType } from '../config/config'

export const useMenuItems = (): ConfigMenuItemsType[] => {
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const menuItemsStatus = useMenuItemsStatus()

  const menuItems = useMemo(() => {
    return config(t, languageCode)
  }, [t, languageCode])

  return useMemo(() => {
    return menuItems.map((item) => {
      const hasSubItems = item.items && item.items.length > 0;
      let innerItems = item.items || [];
      if (menuItemsStatus && Object.keys(menuItemsStatus).length > 0) {
        innerItems = innerItems.map((innerItem) => {
          const itemStatus = menuItemsStatus[innerItem.href]
          if (itemStatus) {
            let itemMenuStatus
            if (itemStatus === 'soon') {
              itemMenuStatus = menuStatus.SOON
            } else if (itemStatus === 'live') {
              itemMenuStatus = menuStatus.LIVE
            } else {
              itemMenuStatus = menuStatus.NEW
            }
            return { ...innerItem, status: itemMenuStatus }
          }
          return innerItem
        });
      }

      return { ...item, items: innerItems, hasSubItems }
    });
  }, [menuItems, menuItemsStatus])
}
