import { SetStateAction, useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  AvaxIcon,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
  Text,
} from '@pancakeswap/uikit'
import Image from 'next/image'
import styled from 'styled-components'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router' 
import { useGetBnbBalance } from 'hooks/useTokenBalance'
// import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/constants'
import { CHAINS_STARGATE } from './stargate/config'

const ExternalUserMenuLink = styled.a`
  width: 100%;
`
const CopyAddress = styled.a`
  color: ${({ theme }) => theme.colors.text};
  display: block;
  width: 110px;
  text-align: center;
  margin: 0 auto 10px auto;
  font-size: 14px;
  padding: 4px 0 4px 4px;
  height: 26px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.tertiary};
    border-radius: 12px;
  }
  &:active {
    transform: translateY(1px);
  }
`

const NetworkLinkContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 10px 14px;
  padding: 1px 0 0 0;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 14px;
  // background: ${({ theme }) => theme.colors.tertiary};
  textalign: center;
`

const NetworkLinkActive = styled.a`
  position: relative;
  width: 120px;
  text-align: center;
  margin: 0 6px 0 0;
  padding: 4px 6px 4px 4px;
  height: 27px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
  &:last-child {
    margin-right: 0;
  }
`
const NetworkLinkInactive = styled.a`
  position: relative;
  width: 120px;
  text-align: center;
  margin: 0 6px 0 0;
  padding: 4px 6px 4px 4px;
  height: 16px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
  &:last-child {
    margin-right: 0;
  }
`

async function switchNetwork(chainId: number) {
  const chain = CHAINS_STARGATE.find((c) => c.id === chainId)
  const provider = window.stargate?.wallet?.ethereum?.signer?.provider?.provider ?? (window as any).ethereum
  if (chain && provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return true
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chain.id.toString(16)}`,
                chainName: chain.name,
                nativeCurrency: chain.nativeCurrency,
                rpcUrls: chain.rpcUrls.default.http,
                blockExplorerUrls: chain.blockExplorerUrls,
              },
            ],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network', error)
          return false
        }
      }
      return false
    }
  }
  return false
}

const BridgeMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error, library } = useWeb3React()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.imageComp
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const [chainId, setChainId] = useState<number | null>(null)
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError


  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])


  useEffect(() => {
    const ethereum = window.ethereum;

    if (!ethereum) {
      console.log("No window.ethereum found");
      return;
    }
    
    const handleChainChange = () => {
      ethereum.request({ method: "eth_chainId" }).then((newChainId) => {
        // @ts-ignore
        return setChainId(parseInt(newChainId, 16));
      });
    };

    ethereum.request({ method: "eth_chainId" }).then((currentChainId) => {
      // @ts-ignore
      setChainId(parseInt(currentChainId, 16));
      return ethereum.on("chainChanged", handleChainChange);
    });

  }, []);



  const BridgeMenuItems = () => {
    return (
      <>
        <Box px="16px" pt="16px" pb="8px">
          <Text>Select a Network:</Text>
        </Box>
        <UserMenuDivider />
        {CHAINS_STARGATE.map((chain) => (
          <UserMenuItem key={chain.id} style={{ justifyContent: 'flex-start' }} onClick={() => switchNetwork(chain.id)}>
            <Image width={24} height={24} src={`/images/chains/${chain.id}.png`} unoptimized alt={`chain-${chain.name}`} />
            <Text pl="12px">{chain.name}</Text>
          </UserMenuItem>
        ))}
        <UserMenuDivider />
        <UserMenuItem onClick={() => window?.stargate.wallet.disconnect() && logout() }>
          <Text>Disconnect</Text>
        </UserMenuItem>
      </>
    )
  }


  if (account) {
    return (
      <UIKitUserMenu
        account={account}
        avatarSrc={null}
        /* {avatarSrc} */ text={userMenuText}
        variant={userMenuVariable}
      >
        {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    if (window.location.pathname === '/bridge') {
      if (chainId === 43114){
        return (
          <UIKitUserMenu text={t('Avalanche')} variant='avax' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 56){
        return (
          <UIKitUserMenu text={t('BNB Chain')} variant='bsc' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 1){
        return (
          <UIKitUserMenu text={t('Ethereum')} variant='eth' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 42161){
        return (
          <UIKitUserMenu text={t('Arbitrum One')} variant='arbitrum' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 10){
        return (
          <UIKitUserMenu text={t('Optimism')} variant='optimism' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 137){
        return (
          <UIKitUserMenu text={t('Polygon')} variant='polygon' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
      if (chainId === 250){
        return (
          <UIKitUserMenu text={t('Fantom')} variant='fantom' > 
            {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
          </UIKitUserMenu>
        )
      }
    }
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <BridgeMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="sm">
      <Box display={['none', , , 'block']}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default BridgeMenu
