export var variants = {
    DEFAULT: "default",
    WARNING: "warning",
    DANGER: "danger",
    PENDING: "pending",
    /* include chains for bridge: */ AVAX: "avax",
    ETH: "eth",
    ARBITRUM: "arbitrum",
    OPTIMISM: "optimism",
    POLYGON: "polygon",
    BSC: "bsc",
    FANTOM: "fantom"
};
