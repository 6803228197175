export var breakpointMap = {
    xs: 370,
    sm: 576,
    md: 852,
    lg: 968,
    xl: 1080,
    xxl: 1200,
    xxxl: 2200
};
var breakpoints = Object.values(breakpointMap).map(function(breakpoint) {
    return "".concat(breakpoint, "px");
});
var mediaQueries = {
    xs: "@media screen and (min-width: ".concat(breakpointMap.xs, "px)"),
    sm: "@media screen and (min-width: ".concat(breakpointMap.sm, "px)"),
    md: "@media screen and (min-width: ".concat(breakpointMap.md, "px)"),
    lg: "@media screen and (min-width: ".concat(breakpointMap.lg, "px)"),
    xl: "@media screen and (min-width: ".concat(breakpointMap.xl, "px)"),
    xxl: "@media screen and (min-width: ".concat(breakpointMap.xxl, "px)"),
    xxxl: "@media screen and (min-width: ".concat(breakpointMap.xxxl, "px)"),
    nav: "@media screen and (min-width: ".concat(breakpointMap.lg, "px)")
};
export var shadows = {
    level1: "0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)",
    active: "0px 0px 0px 2px #0098A1",
    success: "0px 0px 0px 2px #08C8FF",
    warning: "0px 0px 0px 2px #EC34F5",
    focus: "0px 0px 0px 2px #D348F5",
    inset: "inset 0px 2px 3px 0px rgba(74,74,104,0.12), inset 0px -2px 2px -2px rgba(74,74,104,0.10)",
    tooltip: "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1)"
};
var spacing = [
    0,
    4,
    8,
    16,
    24,
    32,
    48,
    64
];
var radii = {
    small: "8px",
    default: "12px",
    card: "12px",
    circle: "50%"
};
var zIndices = {
    ribbon: 9,
    dropdown: 10,
    modal: 100
};
export default {
    siteWidth: 2080,
    breakpoints: breakpoints,
    mediaQueries: mediaQueries,
    spacing: spacing,
    shadows: shadows,
    radii: radii,
    zIndices: zIndices
};
