import { Currency, currencyEquals, JSBI, Price } from '@mkrman/sdk'
import tokens from 'config/constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { multiplyPriceByAmount } from 'utils/prices'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { PairState, usePairs } from './usePairs'

const { wavax: WAVAX, usdc } = tokens

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency(currency, chainId)
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [chainId && wrapped && currencyEquals(WAVAX, wrapped) ? undefined : currency, chainId ? WAVAX : undefined],
      [wrapped?.equals(usdc) ? undefined : wrapped, usdc],
      [chainId ? WAVAX : undefined, usdc],
    ],
    [chainId, currency, wrapped],
  )
  const [[avaxPairState, avaxPair], [usdcPairState, usdcPair], [usdcAvaxPairState, usdcAvaxPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle wavax/avax
    if (wrapped.equals(WAVAX)) {
      if (usdcPair) {
        const price = usdcPair.priceOf(WAVAX)
        return new Price(currency, usdc, price.denominator, price.numerator)
      }
      return undefined
    }
    // handle usdc
    if (wrapped.equals(usdc)) {
      return new Price(usdc, usdc, '1', '1')
    }

    const avaxPairAVAXAmount = avaxPair?.reserveOf(WAVAX)
    const avaxPairAVAXBUSDValue: JSBI =
      avaxPairAVAXAmount && usdcAvaxPair ? usdcAvaxPair.priceOf(WAVAX).quote(avaxPairAVAXAmount).raw : JSBI.BigInt(0)

    // all other tokens
    // first try the busd pair
    if (usdcPairState === PairState.EXISTS && usdcPair && usdcPair.reserveOf(usdc).greaterThan(avaxPairAVAXBUSDValue)) {
      const price = usdcPair.priceOf(wrapped)
      return new Price(currency, usdc, price.denominator, price.numerator)
    }
    if (avaxPairState === PairState.EXISTS && avaxPair && usdcAvaxPairState === PairState.EXISTS && usdcAvaxPair) {
      if (usdcAvaxPair.reserveOf(usdc).greaterThan('0') && avaxPair.reserveOf(WAVAX).greaterThan('0')) {
        const avaxUsdcPrice = usdcAvaxPair.priceOf(usdc)
        const currencyAvaxPrice = avaxPair.priceOf(WAVAX)
        const busdPrice = avaxUsdcPrice.multiply(currencyAvaxPrice).invert()
        return new Price(currency, usdc, busdPrice.denominator, busdPrice.numerator)
      }
    }

    return undefined
  }, [chainId, currency, avaxPair, avaxPairState, usdcAvaxPair, usdcAvaxPairState, usdcPair, usdcPairState, wrapped])
}

export const useCakeBusdPrice = (): Price | undefined => {
  const cakeBusdPrice = useUSDCPrice(tokens.zap)
  return cakeBusdPrice
}

export const useBUSDCurrencyAmount = (currency?: Currency, amount?: number): number | undefined => {
  const { chainId } = useActiveWeb3React()
  const busdPrice = useUSDCPrice(currency)
  if (!amount) {
    return undefined
  }
  const wrapped = wrappedCurrency(currency, chainId)
  if (busdPrice) {
    return multiplyPriceByAmount(busdPrice, amount, wrapped.decimals)
  }
  return undefined
}

export const useBUSDCakeAmount = (amount: number): number | undefined => {
  const cakeBusdPrice = useCakeBusdPrice()
  if (cakeBusdPrice) {
    return multiplyPriceByAmount(cakeBusdPrice, amount)
  }
  return undefined
}

export const useAvaxUsdcPrice = (): Price | undefined => {
  const avaxUsdcPrice = useUSDCPrice(tokens.wavax)
  return avaxUsdcPrice
}
