import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, DiscordIcon, MediumIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.zswap.plus/contact-us",
                openInNewTab: true
            },
            {
                label: "Blog",
                href: "https://medium.com/@zswapdex/",
                openInNewTab: true
            },
            {
                label: "Community",
                href: "https://docs.zswap.plus/contact-us/telegram",
                openInNewTab: true
            },
            {
                label: "ZAP",
                href: "https://docs.zswap.plus/tokenomics/zap",
                openInNewTab: true
            },
            {
                label: "—"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/zswapdex"
    },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/7VpDn8paCr"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/zswapdex"
    },
    /*
  {
    label: "Telegram",
    icon: TelegramIcon,
    items: [
      {
        label: "General",
        href: "https://t.me/zswapdex", // GENERAL CHANNEL
      },
      {
        label: "Announcements",
        href: "https://t.me/zswapdex", // ANNOUNCEMENTS CHANNEL
      },
    ],
  },
  */ /*
  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com/r/zswapdex",
  },
  {
    label: "Instagram",
    icon: InstagramIcon,
    href: "https://instagram.com/zswapdex",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/zswapdex/",
  }, */ {
        label: "Medium",
        icon: MediumIcon,
        href: "https://medium.com/@zswapdex/"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
