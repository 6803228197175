var variants = {
    warning: {
        background: "#FC37FF19",
        borderColor: "warning"
    },
    danger: {
        background: "#EC34F519",
        borderColor: "failure"
    },
    success: {
        background: "rgba(49, 208, 170, 0.1)",
        borderColor: "success"
    }
};
export default variants;
