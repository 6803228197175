import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Input, Text, LoadingSpinnerMini, IconButton, CloseIcon, Flex, SearchIcon } from '@pancakeswap/uikit'
import CircleLoader from 'components/Loader/CircleLoader'
import styled from 'styled-components';
import tokenList from '../../../config/constants/tokenLists/zswap-default.tokenlist.json'


const SearchModalContainer = styled.div`
  display: block;
  position: absolute;
  top: 70px;
  max-width: 90%;
`
const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 18px;
  left: 18px;

`
const InstructionText = styled(Text)`
  position: absolute;
  right 20px;
  bottom: 6px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.textSubtle };
`
const SearchInput = styled(Input)`
  width: 100%;
  padding: 10px 20px 10px 54px;
  font-size: 16px;
  line-height: 40px;
  height: 60px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder };
  background: ${({ theme }) => theme.colors.input };
  width: 520px;
  max-width: 100%;
  overflow: hidden;
`;

const SearchResultContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 64px;
  max-height: 68vh;
  overflow-y: auto;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.background };
  box-shadow: ${({ theme }) => theme.isDark ? 'rgb(10 10 10) 0px 1px 4px, rgb(216 0 255 / 15%) 0px 0px 15px' : 'rgb(14 14 24 / 20%) 0px 0px 6px'};
  margin-top: 4px;
`;

const SearchResultItem = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder };
  cursor: pointer;
  background: ${({ isHighlighted, theme }) => isHighlighted ? '#111C2D' : 'transparent'};
  &:hover{
    background: ${({ theme }) => theme.isDark ? '#111C2D' : 'white' };
  }

  img {
    width: 44px;
    height: 44px;
    margin-right: 14px;
  }
  span {
    font-size: 14px;
  }

  &:last-child{
    border-bottom: 0 none;
  }
`;

const SearchItemText = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: Calc(100% - 56px);

`
const HistoryHeading = styled(Text)`
  margin: 14px 20px 4px; 
  font-size: 600;
`

const StyledCloseButton = styled(IconButton)`
  &:hover{
    background: transparent;
    svg{
      fill: ${({ theme }) => theme.colors.success };
    }
  }
`

const SearchModal: React.FC<{ onDismiss?: () => void }> = ({ onDismiss }) => {
  const router = useRouter();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<Record<string, boolean>>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [searchHistory, setSearchHistory] = useState<(typeof tokenList.tokens[0])[]>([]);
  const [filteredResults, setFilteredResults] = useState<(typeof tokenList.tokens[0])[]>([]);

  // const { data: apiCollections, status } = useGetCollections();
  const [tokens] = useState(tokenList.tokens);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    // Load search history from local storage
    const history = localStorage.getItem('searchHistory');
    if (history) {
      setSearchHistory(JSON.parse(history));
    }
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery !== '') {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filteredTokens = tokens.filter(token => 
          token.name.toLowerCase().includes(lowercasedQuery) || 
          token.symbol.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredResults([...filteredTokens]);
    } else {
      setFilteredResults(searchHistory);
    }
  }, [searchQuery, tokens, searchHistory]);

  const handleItemClick = async (item: typeof tokenList.tokens[0], searchLink: string) => {
    setLoadingStatus({ ...loadingStatus, [item.address]: true }); // Set loading for this item

    // Update search history in local storage
    const updatedHistory = [item, ...searchHistory.filter(h => h.address !== item.address)];
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
    await router.push(`${searchLink}`);    
    // setSearchHistory(updatedHistory);

    onDismiss?.();
    setLoadingStatus({ ...loadingStatus, [item.address]: false }); // Reset loading after navigation
  };

  const handleRemoveItem = (address: string) => {
    const updatedHistory = searchHistory.filter(item => item.address !== address);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
    setSearchHistory(updatedHistory);
  };

  const isItemInHistory = (address: string) => {
    return searchHistory.some(item => item.address === address);
  };


  // Keyboard shortcuts:
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prevIndex) => 
          prevIndex < filteredResults.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prevIndex) => 
          prevIndex > -1 ? prevIndex - 1 : 0
        );
      } else if (e.key === 'Enter' && highlightedIndex > -1) {
        const selectedItem = filteredResults[highlightedIndex];
        handleItemClick(selectedItem, 'avatar' in selectedItem ? `/nfts/collections/${selectedItem.address}` : `/info/token/${selectedItem.address}`);
      } else if (e.key === 'Escape') {
        onDismiss?.();
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [filteredResults, highlightedIndex]);

  return (
    <SearchModalContainer style={{ zIndex: '40' }}>
      <div>
      <StyledSearchIcon width="24px" color="textSubtle" />
      <InstructionText>ESC / ENTER</InstructionText>
      <SearchInput
        type="text"
        placeholder="Search Listed Tokens"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        onFocus={() => setIsSearchActive(true)}
      onBlur={() => setIsSearchActive(false)}
        autoFocus
      />
      </div>
      <SearchResultContainer>
        {searchQuery === '' && searchHistory.length > 0 && (
            <HistoryHeading>Recent</HistoryHeading>
          )}
        {filteredResults.map((item, index) => {
          const isCollection = 'avatar' in item;
          const imageUrl = isCollection ? item.avatar : item.logoURI;
          const altText = item.name;
          const inHistory = isItemInHistory(item.address);
          const isItemLoading = loadingStatus[item.address];
          
          return (
            <SearchResultItem 
              key={item.address} 
              onClick={() => handleItemClick(item, isCollection ? `/nfts/collections/${item.address}` : `/info/token/${item.address}` )}
              isHighlighted={index === highlightedIndex}
              >
              <img style={{ borderRadius: isCollection ? '8px' : '50%' }} 
                src={String(imageUrl)} alt={altText} />
              <SearchItemText>
                <Box>
                  <Text>{item.name} ({item.symbol})</Text>
                  <Text color="textSubtle" fontSize="12px" mt="2px">{isCollection ? 
                    'ERC721 NFT Collection' : 
                      item.address === '0x04531Ae0E4Db1A359847Bd6d025044F7eB2CFb3B' ? 
                        'ERC404 Token' : 
                        'ERC20 Token'
                    }</Text>
                </Box>
                <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                {isItemLoading ? (
                  <CircleLoader size="24px" style={{ marginRight: '5px'  }} />
                ) : inHistory && searchQuery === '' && (
                  <StyledCloseButton onClick={(e: { stopPropagation: () => void }) => { e.stopPropagation(); handleRemoveItem(item.address); }}
                    variant="text" scale="sm" style={{ marginRight: 0 }}>
                    <CloseIcon />
                  </StyledCloseButton>
                )}
                </Flex>
                
              </SearchItemText>
              
            </SearchResultItem>
          );
        })}
      </SearchResultContainer>
    </SearchModalContainer>
  );
};

export default SearchModal;