export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION_AVAX = process.env.NEXT_PUBLIC_GRAPH_API_PREDICTION_AVAX
export const GRAPH_API_PREDICTION_ZAP = process.env.NEXT_PUBLIC_GRAPH_API_PREDICTION_ZAP

export const GRAPH_API_LOTTERY = process.env.NEXT_PUBLIC_GRAPH_API_LOTTERY
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
export const API_NFT = 'https://nft-api-master.vercel.app/api/v1'  // this wasn't working: process.env.NEXT_PUBLIC_API_NFT
export const API_SERVER = 'http://localhost:3001' // Migration to new API

export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = '' // https://api.thegraph.com/subgraphs/name/pancakeswap/prediction

export const INFO_CLIENT = 'https://api.thegraph.com/subgraphs/name/mkrman/exchange'
export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/mkrman/blocks'

export const GRAPH_API_NFTMARKET = process.env.NEXT_PUBLIC_GRAPH_API_NFT_MARKET
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/'

export const TC_MOBOX_SUBGRAPH = '' // https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3
export const TC_MOD_SUBGRAPH = '' // https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4

export const GALAXY_NFT_CLAIMING_API = 'https://graphigo.prd.galaxy.eco/query'
