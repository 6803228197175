const ZSWAP_EXTENDED = 'https://tokens.zswap.plus/zswap-100.tokenlist.json' // This is the equivalent of Pancake top 100 (not really the default)
const COINGECKO = ''
const CMC = ''

// List of official tokens list
export const OFFICIAL_LISTS = [ZSWAP_EXTENDED]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  /*
  ZSWAP_EXTENDED
  CMC,
  COINGECKO, 
  */

  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
