import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { simpleRpcProvider } from 'utils/providers';

const BLOCK_POLL_INTERVAL = 6000; // 6 seconds

const fetchBlockNumber = async () => {
  const blockNumber = await simpleRpcProvider.getBlockNumber();
  return blockNumber;
};

export const useBlockTime = () => {
  const { data: currentBlock } = useSWR('blockNumber', fetchBlockNumber, {
    refreshInterval: BLOCK_POLL_INTERVAL,
  });
  const [blockHistory, setBlockHistory] = useState([]);
  const [blockStatus, setBlockStatus] = useState('fail');

  useEffect(() => {
    if (currentBlock != null) {
      setBlockHistory((prev) => [...prev, { block: currentBlock, time: Date.now() }].slice(-2));
    }
  }, [currentBlock]);

  useEffect(() => {
    
    if (blockHistory.length === 2) {
      const timeDifference = blockHistory[1].time - blockHistory[0].time;
      const blockDifference = blockHistory[1].block - blockHistory[0].block;
      const blockTime = (timeDifference / blockDifference) / 1000;

      if (blockTime <= 3) {
        setBlockStatus('success');
      } else if (blockTime <= 5) {
        setBlockStatus('warn');
      } else {
        setBlockStatus('fail');
      }
    }
  }, [blockHistory]);

  const lastBlockTime = blockHistory.length === 2
    ? (blockHistory[1].time - blockHistory[0].time) / (blockHistory[1].block - blockHistory[0].block) / 1000
    : 0;

  return { blockStatus, blockTime: lastBlockTime === 0 ? '--.-' : lastBlockTime.toFixed(1) };
};