export default {
  masterChef: {
    43114: '0x37877C82e55799261C5928Fe95739DbF190c86FA',
    97: '0x0000000000000000000000000000000000000000',
  },
  masterChefV1: {
    43114: '0x89BBeF30d7283451eF65d0b847C1546F71F42b78', // Deprecated
    97: '0x0000000000000000000000000000000000000000',
  },
  cakeVault: {
    43114: '0x0000000000000000000000000000000000000000', // Main single stake and long-term staking pool: 0x45c54210128a065de780C4B0Df3d16664f7f859e
    97: '0x0000000000000000000000000000000000000000',
  },
  cakeFlexibleSideVault: {
    43114: '0x0000000000000000000000000000000000000000', // Can be removed... but not sure if it should be
    97: '0x0000000000000000000000000000000000000000',
  },
  multiCall: {
    43114: '0x982E9D27bA4DF17527B56d73322570289C7555fe', // This is a problem... needed for swaps, price data, block
    97: '0x0000000000000000000000000000000000000000',
  },
  zap: {
    43114: '0x474E6115d9d3d783142c4b456cC2395Ed25a2f10', // Is needed for zap estimate - works up until this
    97: '',
  },
  pancakeProfile: {
    43114: '0x0000000000000000000000000000000000000000', // Another problem... breaking change
    97: '0x0000000000000000000000000000000000000000',
  },
  sousChef: {
    97: '0x0000000000000000000000000000000000000000', // Syrup pools deprecated
    43114: '0x0000000000000000000000000000000000000000',
  },
  lotteryV2: {
    97: '0x0000000000000000000000000000000000000000', // tick
    43114: '0x0000000000000000000000000000000000000000',
  },

  zswapNft: {
    43114: '0xd8ce3273cc9f18ab082a81857809e9746c9b6173', // tick (this is the profile nft) eg. Pancake Bunnies
    97: '0x0000000000000000000000000000000000000000',
  },
  bunnyFactory: {
    43114: '0x0000000000000000000000000000000000000000', // tick (profile nft minter)
    97: '0x0000000000000000000000000000000000000000',
  },
  claimRefund: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
  },
  pointCenterIfo: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
  },
  bunnySpecial: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
  },
  tradingCompetitionEaster: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
  },
  tradingCompetitionFanToken: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  tradingCompetitionMobox: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  tradingCompetitionMoD: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  easterNft: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  predictionsAVAX: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  predictionsZAP: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  chainlinkOracleAVAX: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  chainlinkOracleZAP: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  bunnySpecialCakeVault: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  bunnySpecialPrediction: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  bunnySpecialLottery: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  bunnySpecialXmas: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  farmAuction: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  AnniversaryAchievement: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  galaxyNftClaiming: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  nftMarket: {
    43114: '0xd78595C232D5199276c00AD3C1cA9bDAbF430F26',
    97: '',
  },
  nftSale: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  pancakeSquad: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
  iCake: {
    43114: '0x0000000000000000000000000000000000000000',
    97: '',
  },
}
