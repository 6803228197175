import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useBlockTime } from 'hooks/useBlockTime';
import { Box, Text, useTooltip } from '@pancakeswap/uikit';

// Interface for the StatusDot props
interface StatusDotProps {
  blockStatus: string;
}

const StatusDot = styled.span<StatusDotProps>`
  height: 6px;
  width: 6px;
  background-color: ${({ blockStatus }) => 
    blockStatus === 'success' ? '#08C8FF' :
    blockStatus === 'warn' ? '#6C45BA' :
    '#D800FF'};
  border-radius: 50%;
  display: inline-block;
  margin: -1px 3px 1px 0;
`;

const BlockStatusIndicator = () => {
  const theme = useTheme();
  const { blockStatus, blockTime } = useBlockTime();
  const blockStatement = blockStatus === 'success' ? 'fast' : blockStatus === 'warn' ? 'average' : 'poor';

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <Text fontSize="12px" style={{ color: theme.colors.text }} >
      This is the estimated time it takes for a block to be mined on the Avax C-Chain. 
      The current average performance is: 
      <span style={{ marginLeft: '4px', color: blockStatus === 'success' ? '#08C8FF' : blockStatus === 'warn' ? '#6C45BA' : '#D800FF', fontWeight: '600' }}>
        {blockTime} s per block ({blockStatement})
      </span>
    </Text> 
    , {placement: 'top'}
  );

  return (
    <>
      <Box ref={targetRef} >
        {tooltipVisible && tooltip}
        <Text color="textSubtle" fontSize="12px" ><StatusDot blockStatus={blockTime !== undefined ? blockStatus : 'fail'} /> Block time: {blockTime} s</Text>
      </Box>
    </>
  );
};

export default BlockStatusIndicator;