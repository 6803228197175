import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  display: flex;\n  justify-content: flex-end;\n  bottom: 0;\n  height: 24px;\n  font-size: 14px;\n  width: 100%;\n  background: ",
        ";\n  ",
        "\n  z-index: 18;\n  @media all and (max-width: 574px){\n    display: none;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 4px;\n  height: 16px;\n  margin-right: 8px;\n  padding-right: 8px;\n  border-right: 1px solid ",
        ";\n  div{\n    font-size: 12px;\n    line-height: 17px;\n  }\n  &:last-child{\n    border-right: none;\n    margin-right: 0;\n  }\n}"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-size: 14px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border-color: ",
        ";\n  border-top-width: 1px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  padding: 24px 0;\n  margin-bottom: 24px;\n\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  padding-top: 32px;\n  padding-bottom: 0;\n  border-top: 1px solid ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-7c31894a-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.backgroundAlt;
}, function(param) {
    var theme = param.theme;
    return theme.isDark ? "" : "box-shadow: 0px 4px 18px rgba(88,102,126,0.08), 0px 0px 2px rgba(88,102,126,0.12);";
});
export var FixedFooter = styled(Flex).withConfig({
    componentId: "sc-7c31894a-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.colors.backgroundAlt;
}, function(param) {
    var theme = param.theme;
    return theme.isDark ? "border-top: 1px solid #1E324C;" : "box-shadow: 0px 4px 18px rgba(88,102,126,0.10), 0px 0px 4px rgba(88,102,126,0.16);";
});
export var FooterItem = styled(Box).withConfig({
    componentId: "sc-7c31894a-2"
})(_templateObject2(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var StyledList = styled.ul.withConfig({
    componentId: "sc-7c31894a-3"
})(_templateObject3(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-7c31894a-4"
})(_templateObject4(), function(param) {
    var theme = param.theme;
    return theme.colors.secondary;
});
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-7c31894a-5"
})(_templateObject5());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-7c31894a-6"
})(_templateObject6(), darkColors.cardBorder, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-7c31894a-7"
})(_templateObject7(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var StyledText = styled.span.withConfig({
    componentId: "sc-7c31894a-8"
})(_templateObject8(), function(param) {
    var theme = param.theme;
    return theme.colors.textSubtle;
});
