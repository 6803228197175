import { shadows } from "../../theme/base";
import { darkColors, lightColors } from "../../theme/colors";
export var light = {
    background: lightColors.background,
    text: lightColors.text,
    boxShadow: shadows.tooltip
};
export var dark = {
    background: darkColors.backgroundAlt2,
    text: darkColors.text,
    boxShadow: shadows.tooltip
};
