import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n  &:hover{\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Button from "./Button";
var IconButton = styled(Button).withConfig({
    componentId: "sc-87016c63-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return scale === "sm" ? "34px" : "42px";
}, function(param) {
    var scale = param.scale;
    return scale === "sm" ? "34px" : "42px";
}, function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
});
export default IconButton;
