import { ChainId, Token } from '@mkrman/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

export const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  wavax: new Token(
    MAINNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://www.avax.network/',
  ),
  // avax here points to the wavax contract. Wherever the currency AVAX is required, conditional checks for the symbol 'AVAX' can be used
  avax: new Token(
    MAINNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'AVAX',
    'AVAX',
    'https://www.avax.network/',
  ),
  zap: new Token(
    MAINNET,
    '0xC23a0C962C61281F450c282A2EEBbA080Ceeedc7', // Address not created yet - this is old SUBZERO
    18,
    'ZAP',
    'ZSwap ZAP',
    'https://zswap.plus/',
  ),
  usdc: new Token(MAINNET, '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', 6, 'USDC', 'USDC', 'https://www.centre.io/'),
  sponk: new Token(MAINNET, '0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1', 18, 'SPONK', 'Sponk', 'https://sponk.world/'),
  sub: new Token(MAINNET, '0xA0105D7fc6190598523f568001A71164341b0A22', 18, 'SUB', 'Subzero', 'https://subzero.plus/'),
  zshare: new Token(
    MAINNET,
    '0xF5b1A0d66856CBF5627b0105714a7E8a89977349',
    18,
    'ZSHARE',
    'ZShare',
    'https://subzero.plus/',
  ),
  warn: new Token(
    MAINNET,
    '0x15E2d9f6C550f79b3A394F8E53Aa552e097CC40B',
    18,
    'WARN',
    'WARN',
    'https://www.warningtest.com/',
  ),
} as const)

export const testnetTokens = defineTokens({
  wavax: new Token(
    TESTNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://www.avax.network/',
  ),
  // avax here points to the wavax contract. Wherever the currency AVAX is required, conditional checks for the symbol 'AVAX' can be used
  avax: new Token(
    TESTNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'AVAX',
    'AVAX',
    'https://www.binance.com/',
  ),
  zap: new Token(
    TESTNET,
    '0xC23a0C962C61281F450c282A2EEBbA080Ceeedc7',
    18,
    'ZAP',
    'ZSwap Token',
    'https://zswap.plus/',
  ),
} as const)

const tokens = () => {
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(CHAIN_ID, 43113) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
