import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#EC34F5",
    primary: "#08C8FF",
    primaryBright: "#53DEE9",
    primaryDark: "#0098A1",
    secondary: "#D800FF",
    success: "#08C8FF",
    warning: "#D800FF"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    background: "#F3F3F3",
    backgroundDisabled: "#F9F7FF",
    backgroundAlt: "#FFFFFF",
    backgroundAlt2: "#EFEFEF",
    cardBorder: "#E2E2E2",
    cardHighlight: "linear-gradient(270deg,rgba(8,200,255,1) 0%,rgba(240,50,245,1) 100%)",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EAEAEA",
    invertedContrast: "#FFFFFF",
    input: "#FAFAFA",
    inputSecondary: "#D7CAEC",
    tertiary: "#EAEAEA",
    text: "#343E57",
    textDisabled: "#BDC2C4",
    textSubtle: "#6e7c84",
    disabled: "#E9EAEB",
    transparent: "transparent",
    green: "#48bb78",
    red: "#e53e3e",
    avax: "#E84142",
    gradients: {
        bluePink: "linear-gradient(270deg,rgba(8,200,255,1) 0%,rgba(240,50,245,1) 100%)",
        bubblegum: "#FFFFFF",
        inverseBubblegum: "linear-gradient(100deg, rgb(211 243 255) 0%, rgb(255 237 255) 100%)",
        cardHeader: "#F8F8F8",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#D800FF",
    background: "#0E1525",
    backgroundDisabled: "#0D1321",
    backgroundAlt: "#0D111F",
    backgroundAlt2: "#111C2D",
    cardBorder: "#1E324C",
    cardHighlight: "linear-gradient(270deg,rgba(8,200,255,1) 0%,rgba(240,50,245,1) 100%)",
    contrast: "#FFFFFF",
    dropdown: "#000000",
    dropdownDeep: "#111C2D",
    invertedContrast: "#191326",
    input: "#111C2D",
    inputSecondary: "#0a0b18",
    primaryDark: "#0098A1",
    tertiary: "#181E2F",
    text: "#F4EEFF",
    textDisabled: "#666171",
    textSubtle: "#A2A4C1",
    disabled: "#524B63",
    transparent: "transparent",
    green: "#48bb78",
    red: "#e53e3e",
    avax: "#E84142",
    gradients: {
        bluePink: "linear-gradient(270deg,rgba(8,200,255,1) 0%,rgb(248,37,255) 100%)",
        bubblegum: "linear-gradient(100deg,rgb(12 15 29) 0%,rgb(17 29 46) 100%)",
        inverseBubblegum: "linear-gradient(100deg, rgb(17 29 46) 0%, rgb(12 15 29) 100%)",
        cardHeader: "linear-gradient(100deg,rgb(12 15 29) 0%,rgb(17 29 46) 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
