import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n\n  ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: ",
        ";\n  font-size: 14px;\n  font-weight: ",
        ";\n\n  ",
        "\n\n  ",
        "\n  \n  ",
        "\n  \n  &:hover {\n    background: ",
        ";\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var StyledMenuItemContainer = styled.div.withConfig({
    componentId: "sc-8b4df5cd-0"
})(_templateObject(), function(param) {
    var $isActive = param.$isActive, $variant = param.$variant, theme = param.theme;
    return $isActive && $variant === "subMenu" && '\n      &:after{\n        content: "";\n        position: absolute;\n        bottom: 0;\n        width: 100%;\n        background-color: '.concat(theme.colors.primary, ";\n        border-radius: 2px 2px 0 0;\n      }\n    ");
});
var StyledMenuItem = styled.a.withConfig({
    componentId: "sc-8b4df5cd-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, $isActive = param.$isActive;
    return $isActive ? theme.colors.secondary : theme.colors.textSubtle;
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "600" : "600";
}, function(param) {
    var $statusColor = param.$statusColor, theme = param.theme;
    return $statusColor && '\n    &:after {\n      content: "";\n      border-radius: 100%;\n      background: '.concat(theme.colors[$statusColor], ";\n      height: 6px;\n      width: 6px;\n      margin-left: 6px;\n    }\n  ");
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" ? "\n    padding: 0 12px;\n    height: 50px;\n    line-height: 50px;\n    @media all and (max-width: 799px){\n      padding: 0 4px;\n    }\n  " : "\n    padding: 4px 4px 4px 4px;\n    height: 42px;\n  ";
}, function(param) {
    var $variant = param.$variant;
    return $variant === "subMenu" && "\n    margin-right: 24px;\n    z-index: 10;\n    @media all and (max-width: 499px){\n      margin-right: 12px;\n      padding: 4px 4px 4px 4px;\n    }\n  ";
}, function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" && "border-radius: 8px;";
});
export default StyledMenuItem;
