import Link from 'next/link'
import { Flex, IconButton, CogIcon, useModal, SearchIcon, AccountIcon } from '@pancakeswap/uikit'
import styled from 'styled-components';
import SettingsModal from './SettingsModal'
import SearchModal from './SearchModal'



type Props = {
  color?: string
  mr?: string
  showSearch?: boolean
  showProfile?: boolean
}

const ProfileIconButton = styled(IconButton)`
  @media all and (max-width: 576px){
    display: none;
  }
`


const GlobalSettings = ({ color, mr = '8px', showSearch, showProfile }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [onPresentSearchModal] = useModal(<SearchModal />)

  return (
    <Flex>
      {showSearch && (
        <IconButton onClick={() => onPresentSearchModal()} variant="text" scale="sm" mr={mr}>
          <SearchIcon height={22} width={22} mt="1px" color={color || 'textSubtle'} />
        </IconButton>
      )}
      {/*
        {showProfile && (
          <Link href="/profile">
            <ProfileIconButton variant="text" scale="sm" mr={mr}>
              <AccountIcon height={20} width={20} mt="1px" color={color || 'textSubtle'} />
            </ProfileIconButton>
          </Link>
        )}
      */}
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <CogIcon height={22} width={22} color={color || 'textSubtle'} />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
