// used to construct the list of all pairs we consider by default in the frontend
import { ChainId, Token } from '@mkrman/sdk'
import { mainnetTokens, testnetTokens } from './tokens'
import { ChainTokenList } from './types'

export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [mainnetTokens.wavax, mainnetTokens.zap],
  [ChainId.TESTNET]: [testnetTokens.wavax, testnetTokens.zap],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [[mainnetTokens.wavax, mainnetTokens.sub]],
}

export const NetworkContextName = 'NETWORK'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = ['']

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 30
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const NOT_ON_SALE_SELLER = '0x0000000000000000000000000000000000000000'

export const FARM_AUCTION_HOSTING_IN_SECONDS = 604800

export const PREDICTION_TOOLTIP_DISMISS_KEY = 'prediction-switcher-dismiss-tooltip'

// Gelato uses this address to define a native currency in all chains
export const GELATO_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const EXCHANGE_DOCS_URLS = 'https://docs.zswap.plus'

export const GALAXY_NFT_CAMPAIGN_ID = ''
