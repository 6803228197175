import { Flex, Text, UserMenuItem, WalletIcon, WarningIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { FetchStatus } from 'config/constants/types'
import { LOW_AVAX_BALANCE } from './WalletModal'

interface WalletUserMenuItemProps {
  isWrongNetwork: boolean
  onPresentWalletModal: () => void
}

const WalletUserMenuItem: React.FC<WalletUserMenuItemProps> = ({ isWrongNetwork, onPresentWalletModal }) => {
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_AVAX_BALANCE)

  return (
    <UserMenuItem as="button" onClick={onPresentWalletModal}>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Text color="textSubtle">
          {/* <WalletIcon style={{ margin: '4px 10px -4px 0' }} /> */}
          {t('Wallet')}
        </Text>
        {hasLowBnbBalance && !isWrongNetwork && <WarningIcon color="warning" width="20px" />}
        {isWrongNetwork && <WarningIcon color="failure" width="20px" />}
      </Flex>
    </UserMenuItem>
  )
}

export default WalletUserMenuItem
