import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'https://docs.zswap.plus/contact-us',
        openInNewTab: true
      },
      {
        label: t('Brand'),
        href: 'https://docs.zswap.plus/brand',
        openInNewTab: true
      },
      {
        label: t('News'),
        href: 'https://medium.com/@zswapdex',
        openInNewTab: true
      },
      {
        label: '—',
      },
      /*
      {
        label: t('Online Store'),
        href: 'https://pancakeswap.creator-spring.com/',
      },
      */
    ],
  },
  {
    label: t('Analytics'),
    items: [
      {
        label: t('DefiLlama'),
        href: 'https://defillama.com/protocol/subzero-zswap',
        openInNewTab: true
      },
      
      {
        label: t('ZSwap Info'),
        href: '/info',
        isHighlighted: true,
      },
      {
        label: '—',
      },
    ],
  }
  /*
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: t('Bug Bounty'),
        href: 'https://docs.pancakeswap.finance/code/bug-bounty',
      },
      {
        label: t('Audits'),
        href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      },
      {
        label: t('Careers'),
        href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      },
    ],
  
  },
  */
]
