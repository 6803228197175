import { Chain  } from "wagmi";

interface Chains extends Chain{
  blockExplorerUrls: string[];
}

export const avalanche: Chains = {
  id: 43114,
  name: "Avalanche C-Chain",
  network: "avalanche",
  rpcUrls: {
    default: {
        http: ["https://api.avax.network/ext/bc/C/rpc"],
    },
    public: {
        http: ["https://api.avax.network/ext/bc/C/rpc"],
    },
  },
  blockExplorerUrls: [`https://snowtrace.io/`],
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 11907934,
    },
  },
  nativeCurrency: { 
    name: "Avalanche", 
    symbol: "AVAX", 
    decimals: 18 },
};

export const avalancheFuji: Chains = {
  id: 43113,
  name: "Avalanche Fuji",
  network: "avalanche-fuji",
  rpcUrls: {
    default: {
      http: ["https://rpc.ankr.com/avalanche_fuji"],
     },
     public: {
      http: ["https://rpc.ankr.com/avalanche_fuji"],
     },
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorerUrls: [`https://testnet.snowtrace.io/`],
  testnet: true,
};

export const bsc: Chains = {
  id: 56,
  name: "BNB Chain",
  network: "bsc",
  nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
  },
  rpcUrls: {
      default: {
          http: ["https://rpc.ankr.com/bsc"],
      },
      public: {
          http: ["https://rpc.ankr.com/bsc"],
      },
  },
  blockExplorerUrls: [`https://bscscan.com`],
  contracts: {
      multicall3: {
          address: "0xca11bde05977b3631167028862be2a173976ca11",
          blockCreated: 15921452,
      },
  },
}


export const mainnet: Chains = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "mainnet",
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/eth"], },
    public: { http: ["https://rpc.ankr.com/eth"], },
  },
  blockExplorerUrls: [`https://etherscan.io/`],
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
};

export const arbitrum: Chains = {
  id: 42161,
  name: "Arbitrum One",
  network: "arbitrum",
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/arbitrum"], },
    public: { http: ["https://rpc.ankr.com/arbitrum"], },
  },
  blockExplorerUrls: [`https://arbiscan.io/`],
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
};

export const optimism: Chains = {
  id: 10,
  name: "Optimism",
  network: "optimism",
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/optimism"], },
    public: { http: ["https://rpc.ankr.com/optimism"], },
  },
  blockExplorerUrls: [`https://optimistic.etherscan.io/`],
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
};

export const polygon: Chains = {
  id: 137,
  name: "Polygon",
  network: "polygon",
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/polygon"], },
    public: { http: ["https://rpc.ankr.com/polygon"], },
  },
  blockExplorerUrls: [`https://polygonscan.com/`],
  nativeCurrency: {
    name: "MATIC",
    symbol: "matic",
    decimals: 18,
  },
};

export const fantomOpera: Chains = {
  id: 250,
  name: "Fantom",
  network: "fantom",
  rpcUrls: {
    default: { http: ["https://rpc.ftm.tools"], },
    public: { http: ["https://rpc.ftm.tools"], },
  },
  blockExplorerUrls: [`https://ftmscan.com`],
  nativeCurrency: { 
    name: "Fantom", 
    symbol: "FTM", 
    decimals: 18 
  },
};


// not used
export const CHAINS_TESTNET = [avalanche, mainnet, bsc, polygon, arbitrum, optimism, fantomOpera ];

// not used:
export const CHAINS_STARGATE_TESTNET = [avalanche, mainnet, bsc, polygon, arbitrum, optimism, fantomOpera ];

// use this:
export const CHAINS = [avalanche, mainnet, bsc, polygon, arbitrum, optimism, fantomOpera ];
