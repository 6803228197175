import { Box, BoxProps } from '@pancakeswap/uikit'

interface ContainerProps extends BoxProps{
  pageWidth?: 'standard' | 'wide' | 'ultrawide'
}

const Container: React.FC<ContainerProps> = ({ pageWidth, children, ...props }) => (
  <Box px={['16px', '24px']} mx="auto" maxWidth={ pageWidth === 'ultrawide' ? '2280px' : '1680px'} {...props}>
    {children}
  </Box>
)

export default Container
