import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  CardViewIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  AnalyticsFillIcon,
  AnalyticsIcon,
  StarFillIcon,
  StarLineIcon,
  NoProfileAvatarIcon,
  AccountIcon,
  AccountFilledIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { DropdownMenuItems, LinkStatus } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "warning",
  },
  SOON: <LinkStatus>{
    text: "SOON",
    color: "warning",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "warning",
  },
};

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('DEX'),
    href: '/swap',
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    // hideSubNav: true,
    // showItemsOnMobile: false,
    items: [
      {
        label: t('Swap'),
        href: '/swap',
      },
      /*
      {
        label: t('Limit Orders'),
        href: '/limit-orders',
      }, 
      */
      {
        label: t('Provide Liquidity'),
        href: '/liquidity',
      },
      /*
      {
        label: t('Launcher'),
        href: '/launcher',
      }, */
      /*
      {
        label: t('Perpetual'),
        href: `https://perp.pancakeswap.finance/${perpLangMap(languageCode)}/futures/BTCUSDT`,
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      */
      {
        label: t('Bridge'),
        href: '/bridge',
      },
    ],
  },
  /*
  {
    label: t('Farm'),
    href: '/farms',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        label: t('LP Farms'),
        href: '/farms',
      },
      {
        label: t('Staking Pools'),
        href: '/pools',
      },
    ],
  }, */
  /*
  {
    label: t('Win'),
    href: '/prediction',
    icon: TrophyIcon,
    fillIcon: TrophyFillIcon,
    items: [
      {
        label: t('Trading Competition'),
        href: '/competition',
        hideSubNav: true,
      },
      {
        label: t('Prediction (BETA)'),
        href: '/prediction',
      },
      {
        label: t('Lottery'),
        href: '/lottery',
      },
    ],
  }, */
  {
    label: 'Info',
    href: '/info',
    icon: AnalyticsIcon,
    fillIcon: AnalyticsFillIcon,
    hideSubNav: true,
    showItemsOnMobile: true,
    items: [
      {
        label: t('Info'),
        href: '/info',
      },
      /*
      {
        label: t('IFO'),
        href: '/ifo',
      },
      {
        label: t('Voting'),
        href: '/voting',
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('Leaderboard'),
        href: '/teams',
      }, */
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('News'),
        href: 'https://medium.com/@zswapdex',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.zswap.plus',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  }
]

export default config
