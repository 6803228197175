import Metamask from "../../components/Svg/Icons/Metamask";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import TokenPocket from "../../components/Svg/Icons/TokenPocket";
import AvalancheCore from "../../components/Svg/Icons/AvalancheCore";
import SafePal from "../../components/Svg/Icons/SafePal";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import Opera from "../../components/Svg/Icons/Opera";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Core Wallet",
        icon: AvalancheCore,
        connectorId: ConnectorNames.Injected,
        priority: 1,
        href: "https://core.app/"
    },
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.Injected,
        priority: 2,
        href: "https://metamask.io/"
    },
    /* USE THIS FOR BSC:
  {
    title: "Binance Wallet",
    icon: BinanceChain,
    connectorId: ConnectorNames.Injected,
    priority: 2,
  }, */ {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 3
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 4,
        href: "https://trustwallet.com/"
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 5
    },
    {
        title: "Opera Wallet",
        icon: Opera,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isOpera) ? 0 : 6;
        },
        href: "https://www.opera.com/crypto/next"
    },
    {
        title: "TokenPocket",
        icon: TokenPocket,
        connectorId: ConnectorNames.Injected,
        priority: 999
    },
    {
        title: "SafePal",
        icon: SafePal,
        connectorId: ConnectorNames.Injected,
        priority: 999
    }
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
