import { useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  AvaxIcon,
  BinanceChainIcon,
  OpenNewIcon,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
  Text,
  Button,
  CopyIcon,
  Link,
  HistoryIcon,
  NftIcon,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { FetchStatus } from 'config/constants/types'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { getSnowtraceLink } from 'utils'
import { copyText } from 'utils/copyText'
import { useAvaxUsdcPrice } from 'hooks/useUSDCPrice'
// import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { formatBigNumber } from 'utils/formatBalance'
import truncateHash from 'utils/truncateHash'
import WalletModal, { WalletView } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'

const ExternalUserMenuLink = styled.a`
  width: 100%;
`
const CopyAddress = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: inline-block;
  text-align: center;
  margin: 0 auto 10px auto;
  font-size: 14px;
  padding: 4px 8px 4px 8px;
  height: 26px;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${({ theme }) => theme.colors.tertiary};
    border-radius: 12px;
  }
  &:active {
    transform: translateY(1px);
  }
`

const CopiedText = styled(Text)`
  display: block;
  position: absolute;
  background: ${({ theme }) => theme.colors.tertiary};
  padding: 0 8px;
  border-radius: 10px;
  left: 50%;
  margin: 4px 0 0 -120px;
  pointer-events: none;
`

const NetworkLinkContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 10px 14px;
  padding: 1px 0 0 0;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 14px;
  // background: ${({ theme }) => theme.colors.tertiary};
  textalign: center;
`

const NetworkLinkActive = styled.a`
  position: relative;
  width: 120px;
  text-align: center;
  margin: 0 6px 0 0;
  padding: 4px 6px 4px 4px;
  height: 27px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
  &:last-child {
    margin-right: 0;
  }
`
const NetworkLinkInactive = styled.a`
  position: relative;
  width: 120px;
  text-align: center;
  margin: 0 6px 0 0;
  padding: 4px 6px 4px 4px;
  height: 16px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
  &:last-child {
    margin-right: 0;
  }
`

const StyledNetworkChangeButton = styled(Button)`
  display: inline-block;
  width: auto;
  margin: 0 0 16px 0;
  border: 2px solid transparent;
  color: #D800FF;
  background: transparent;
  borderRadius: 15px;
  padding: 2px 8px;
  height: auto;
  fontSize: 12px;
  lineHeight: 12px;
  fontWeight: 600;
`

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.imageComp
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  // New state to control the visibility of the "Copied!" text
  const [showCopiedText, setShowCopiedText] = useState(false)

  const handleCopyAddress = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent elements
    copyText(account);
    setShowCopiedText(true);

    // Reset the "Copied!" text after 1 second
    setTimeout(() => {
      setShowCopiedText(false);
    }, 1000);
  };

  const UserMenuItems = () => {
    const avaxPriceBusd = useAvaxUsdcPrice()

    const accountEllipsis = account ? `${truncateHash(account) }` : null

    const avaxBalanceBusd = avaxPriceBusd
      ? (Number(avaxPriceBusd.toFixed(2)) * Number(formatBigNumber(balance, 2))).toFixed(2)
      : null

    const avaxBalanceBusdDisplay = avaxBalanceBusd ? `≈ $${avaxBalanceBusd.toLocaleString()} USD` : `...` // $${avaxPriceBusd.toFixed(2)}

    return (
      <>
        {' '}
        {/* 
        <Box style={{ textAlign: 'center' }}>
          <NetworkLinkContainer>
            <NetworkLinkActive href="/" color="text" >
              <AvaxIcon width="17px" style={{ margin: '4px 0 -4px 0' }} /> AVAX
            </NetworkLinkActive>
            
            <NetworkLinkInactive href="/" color="text" >
              <BinanceChainIcon width="17px" style={{ margin: '4px 0 -4px 0' }} /> BNB
            </NetworkLinkInactive>
            <NetworkLinkInactive href="/" color="text" >
              <AvaxIcon width="17px" style={{ margin: '4px 0 -4px 0' }} /> MATIC
            </NetworkLinkInactive>
            
          </NetworkLinkContainer>
        </Box> */}
        {!accountEllipsis ? (
          /* reduce mt below to zero when networks are enabled */
          <Box mt="20px" mb="0px" style={{ textAlign: 'center' }}>
            <StyledNetworkChangeButton
              variant="secondary"
              color="secondary"
              onClick={onClickWalletMenu}
            >
              Change to Avalanche
            </StyledNetworkChangeButton>
          </Box>
        ) : (
          <>
            {/* reduce mt below to zero when networks are enabled */}
            <Box mt="20px" mb="20px" style={{ textAlign: 'center' }}>
              {showCopiedText && <CopiedText
                color="success"
                fontSize="12px"
                textAlign="center"
              >Copied!</CopiedText>}
              <CopyAddress onClick={handleCopyAddress}>
                {accountEllipsis}
                <CopyIcon style={{ margin: '1px 0 -3px 4px' }} width="16px" />
              </CopyAddress>            
              <Text color="text" fontSize="30px" textAlign="center">
                {formatBigNumber(balance, 3)} AVAX
              </Text>
              <Text color="textSubtle" textAlign="center" style={{ margin: '10px 0 0 0' }}>
                ({avaxBalanceBusdDisplay})
              </Text>
            </Box>
            <UserMenuDivider />
            <ExternalUserMenuLink rel="nofollow" target="_blank" href={getSnowtraceLink(account, 'address')}>
              <UserMenuItem as="button" style={{ borderRadius: '0' }}>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  {t('View on Snowtrace')}
                </Flex>
                <OpenNewIcon width="18px" />
              </UserMenuItem>
            </ExternalUserMenuLink>
          </>
        )}
        <UserMenuDivider />
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          <Text color="textSubtle">
            {/* <HistoryIcon style={{ margin: '4px 10px -4px 0' }} /> */}
            {t('Recent Transactions')}
          </Text>
          {hasPendingTransactions && <RefreshIcon width="18px" spin /> }
        </UserMenuItem>
        <ProfileUserMenuItem
          isLoading={null}
          /* {isLoading} */ hasProfile={null}
          /* {hasProfile} */ disabled={isWrongNetwork}
        />
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%" mb="4px">
            {t('Disconnect')}
            <LogoutIcon width="18px" />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu
        account={account}
        avatarSrc={null}
        /* {avatarSrc} */ text={userMenuText}
        variant={userMenuVariable}
      >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="sm">
      <Box display={['none', , , 'block']}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
